import { Controller } from "stimulus";
import { isMobileDevice } from "../../util";
import Cookies from "js-cookie";
import {
  EventFetchPortfolio,
  EventLoadAddCoinsSection,
  EventLoadCategoriesChips,
  EventLoadChainsChips
} from "../../events";

const STEP_1_PAGE_INDEX = 0;
const STEP_3_PAGE_INDEX = 2;
const STEP_4_PAGE_INDEX = 3;

const SHOW_PREFERENCES_STEP_ONLY = 1;
const HIDE_NEWSLETTER = 3;

const ONBOARDING_STEP = {
  0: "choose_path",
  1: "download_app",
  2: "newsletter",
  3: "preferences",
}

export default class extends Controller {
  _step_1_substep_selection = "add-coins-card";
  _totalMaxSteps = 4;
  _pageIndex = STEP_1_PAGE_INDEX;
  _activeStepIndex = STEP_1_PAGE_INDEX;
  _showSkipButtonPage = [STEP_1_PAGE_INDEX, STEP_3_PAGE_INDEX];

  _isLoadedAddCoinsSection = false;
  _isLoadedGetRecommendationsChips = false;

  static targets = [
    // Toggle modal pages
    "pageContainer", "progressIndicator", "skipButton",

    // Step 1
    "addCoinCard", "getRecommendationCard", "addCoinsPage", "getRecommendationPage", "getRecommendationResultsPage",

    // Step 2
    "webView", "mobileView",

    // Step 3
    "newsletterButton",
  ];

  async connect() {
    if (!Cookies.get("close-user-onboarding")) {
      const hasUserInfo = await this._fetchUserInfo();
      if(!hasUserInfo) return;

      this._removeProgressIndicators();
      this._showFirstStep();
      this._showAppCtaBasedOnView();
      this._hideNewsletterPage();
      this._addCloseButtonId();

      window.addEventListener("gecko-modal-close", (e) => {
        if (e.detail.modalId === "coins_user_onboarding") {
          Cookies.set("close-user-onboarding", true, { expires: 365 });
          window.dispatchEvent(new CustomEvent(EventFetchPortfolio));
        }
      });

      this._showModal();
    }
  }

  nextPage() {
    this._pageIndex += 1;
    this._activeStepIndex += 1;

    if (this._pageIndex < this._totalMaxSteps) {
      this._togglePageVisibility();
      this._toggleActiveStep();
      this._toggleSkipButton();

      this._onAddCoinPage = false;

      this._trackEvent("view_onboarding_modal", {
        "onboarding_step": this._currentOnboardingStep(),
        "onboarding_type": this._userOnboardingType(),
      });
    } else {
      Modal.hide("coins_user_onboarding");
    }
  }

  chooseStepCard(e) {
    this._step_1_substep_selection = e.currentTarget.dataset.identifier;
  }

  showSubstepPage() {
    if (this._step_1_substep_selection === "add-coins-card") {
      this._showAddCoinPage();
    } else {
      this._showGetRecommendationPage();
    }
  }

  backToFirstPage() {
    this._togglePageVisibility();
  }

  showGetRecommendationsResultPage() {
    this.getRecommendationPageTarget.classList.add("tw-hidden");
    this.getRecommendationResultsPageTarget.classList.remove("tw-hidden");
  }

  backToChipSelectionPage() {
    this.getRecommendationResultsPageTarget.classList.add("tw-hidden");
    this.getRecommendationPageTarget.classList.remove("tw-hidden");
  }

  scrollToDivider(e) {
    e.currentTarget.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "end" });

    let dividerId = e.currentTarget.dataset.divider;
    let scrollToElement = document.getElementById(dividerId);
    setTimeout(() => {
      scrollToElement?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }, 100);

    this._triggerSelectHighlightPillGaEvent(e);
  }

  appCta() {
    this._trackEvent("click_mobile_web_to_app_cta", {
      "origin": "onboarding",
      "onboarding_type": this._userOnboardingType(),
    });
  }

  newsletterCta() {
    this._trackEvent("click_newsletter_subscribe", {
      "origin": "onboarding",
      "onboarding_type": this._userOnboardingType(),
    });
  }

  skipOnboardingStep() {
    this._trackEvent("skip_onboarding", {
      "onboarding_step": this._currentOnboardingStep(),
      "onboarding_type": this._userOnboardingType(),
    });
  }

  finishOnboarding() {
    this._trackEvent("complete_onboarding", {
      "onboarding_type": this._userOnboardingType(),
    });
  }

  _showModal() {
    Modal.show("coins_user_onboarding");

    this._trackEvent("start_onboarding")
  }

  _showAddCoinPage() {
    this._onAddCoinPage = true;

    this._trackEvent("view_onboarding_modal", {
      "onboarding_step": this._currentOnboardingStep(),
      "onboarding_type": this._userOnboardingType(),
    });

    this._trackEvent("click_onboarding_portfolio_next", {
      "onboarding_step": "choose_path",
      "onboarding_type": this._userOnboardingType(),

      // As recommendation card option is currently disabled, we will only be using the "add_coin" param for now
      "select_option": "add_coin"
    });

    if (!this._isLoadedAddCoinsSection) {
      this._isLoadedAddCoinsSection = true;
      window.dispatchEvent(new CustomEvent(EventLoadAddCoinsSection));
    }

    this.addCoinsPageTarget.classList.remove("tw-hidden");
    this.pageContainerTargets[STEP_1_PAGE_INDEX].classList.add("tw-hidden");
  }

  _showGetRecommendationPage() {
    if (!this._isLoadedGetRecommendationsChips) {
      this._isLoadedGetRecommendationsChips = true;

      window.dispatchEvent(new CustomEvent(EventLoadChainsChips));
      window.dispatchEvent(new CustomEvent(EventLoadCategoriesChips));
    }

    this.getRecommendationPageTarget.classList.remove("tw-hidden");
    this.pageContainerTargets[STEP_1_PAGE_INDEX].classList.add("tw-hidden");
  }

  async _fetchUserInfo() {
    try {
      const response = await fetch(`/onboarding/user_info`);
      if (!response.ok) return false;

      const userInfo = await response.json();
      if (!userInfo.show_modal) return false;

      this._userEmail = userInfo.email;
      this._totalMaxSteps = userInfo.steps;
      this._newUser = userInfo.new_user;

      this.newsletterButtonTarget.setAttribute("data-email", this._userEmail);
      return true;
    } catch {
      return false;
    }
  }

  _hideNewsletterPage() {
    if (this._totalMaxSteps === HIDE_NEWSLETTER) {
      const element = document.getElementById("onboarding-newsletter-page");
      element.remove();

      this._showSkipButtonPage.pop();
    }
  }

  _showFirstStep() {
    if (this._totalMaxSteps === SHOW_PREFERENCES_STEP_ONLY) {
      this._pageIndex = STEP_4_PAGE_INDEX;
    } else {
      this._pageIndex = STEP_1_PAGE_INDEX;
    }

    this._trackEvent("view_onboarding_modal", {
      "onboarding_step": this._currentOnboardingStep(),
      "onboarding_type": this._userOnboardingType(),
    });

    this._toggleSkipButton();
    this._toggleActiveStep();
    this._togglePageVisibility();
  }

  _togglePageVisibility() {
    // hide all substep pages
    this.addCoinsPageTarget.classList.add("tw-hidden");
    this.getRecommendationPageTarget.classList.add("tw-hidden");
    this.getRecommendationResultsPageTarget.classList.add("tw-hidden");

    this.pageContainerTargets.forEach((container, i) => {
      container.classList.toggle("tw-hidden", i !== this._pageIndex);
    });
  }

  _toggleActiveStep() {
    this.progressIndicatorTargets.forEach((step, i) => {
      step.classList.toggle("active", i === this._activeStepIndex);
    });
  }

  _toggleSkipButton() {
    this.skipButtonTarget.classList.toggle("tw-hidden", !this._showSkipButtonPage.includes(this._pageIndex));
  }

  _removeProgressIndicators() {
    const totalElements = this.progressIndicatorTargets.length;
    const numberToRemove = totalElements - this._totalMaxSteps;

    for (let i = 0; i < numberToRemove && totalElements - 1 - i >= 0; i++) {
      this.progressIndicatorTargets[totalElements - 1 - i].remove();
    }
  }

  _showAppCtaBasedOnView() {
    if (isMobileDevice(navigator.userAgent)) {
      this.mobileViewTarget.classList.remove("tw-hidden");
      this.mobileViewTarget.classList.add("tw-flex");
    } else {
      this.webViewTarget.classList.remove("tw-hidden");
      this.webViewTarget.classList.add("tw-flex");
    }
  }

  _addCloseButtonId() {
    const closeButton = this.element.querySelector('[\\@click="coins_user_onboarding = false"]');
    closeButton.setAttribute("id", `onboarding-close`);

    this._addCloseButtonEventListener();
  }

  _addCloseButtonEventListener() {
    const closeButton = document.getElementById("onboarding-close");

    if (closeButton) {
      closeButton.addEventListener("click", () => {
        this._trackEvent("quit_onboarding", {
          "onboarding_step": this._currentOnboardingStep(),
          "onboarding_type": this._userOnboardingType(),
        });
      });
    }
  }

  _userOnboardingType() {
    return this._newUser ? "new" : "continuous";
  }

  _triggerSelectHighlightPillGaEvent(e) {
    let selectHighlightOption = e.currentTarget.dataset.option;

    this._trackEvent("click_add_coins_highlight", {
      "origin": "onboarding",
      "onboarding_type": this._userOnboardingType(),
      "select_highlight_option": selectHighlightOption
    });
  }

  _currentOnboardingStep() {
    if (this._onAddCoinPage) {
      return "add_coins";
    }

    if (this._pageIndex === STEP_3_PAGE_INDEX && this._totalMaxSteps === HIDE_NEWSLETTER) {
      return ONBOARDING_STEP[STEP_4_PAGE_INDEX];
    }

    return ONBOARDING_STEP[this._pageIndex];
  }

  _trackEvent(eventName, eventParams = {}) {
    if (typeof gtag === 'undefined') return;

    gtag("event", eventName, eventParams);
  }
}
